import axios from "./axios";

export const setToken = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const goToOtherUrl = (link) => {
  const newWindow = window.open(link, "_self");
  if (newWindow) newWindow.opener = null;
};

export const updateQueryParams = (newParams) => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  Object.keys(newParams).forEach((key) => {
    if (newParams[key]) {
      const value = newParams[key];
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  });

  const newUrl = url.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const initParams = () => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  url.search = "";

  // Update the URL with the modified query parameters
  const newUrl = url.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const convertParamsToObj = (search) => {
  const params = new URLSearchParams(search);
  const paramsObject = {};

  params.forEach((value, key) => {
    paramsObject[key] = value;
  });
  return paramsObject;
};
